import * as React from "react"
import Header from "../components/Header/Header";
import "./../sass/main.scss"
import Footer from "../components/Footer/Footer";

let lang = process.env.LANG;

const NotFoundPage = () => {
    return (
        <>
            <Header
                title={"Page Not Found | East Side Enterprises"}
                description={"Page Not Found | East Side Enterprises"}
                hidden={false} lang={lang} disableBreadcrumbs={true} bodyClasses={"page-404 splash"}/>
            <main property="mainContentOfPage" className={"container"} resource="#wb-main" typeof="WebPageElement">
                <h1>404 Page Not Found</h1>
                <p>The page you are trying to reach does not exist</p>
                <a href={"/"}>Back to homepage</a>
            </main>
            <Footer/>
        </>
    )
}
export default NotFoundPage;
